import React, { useState, useRef } from 'react';
import './CSS/ContactM.css';
import emailjs from '@emailjs/browser';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaCheckCircle } from 'react-icons/fa'; // Import icons



function ContactM() {
    const form = useRef();

    const [selectedOption, setSelectedOption] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(form.current)
        // emailjs.sendForm('service_os5nhon', 'template_7hovays', form.current, 'Z2OsdXAhl7X1TTLqr')
        emailjs.sendForm('service_aflps1h', 'template_98rly7l', form.current, 'IIFFeZhMLyRez8teD')
            .then(
                (result) => {
                    console.log('SUCCESS!', result.text);
                    form.current.reset();
                    setPopupMessage('Your message has been sent successfully!');
                    setPopupVisible(true);
                    setLoading(false);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setPopupMessage('Failed to send email. Please try again later.');
                    setPopupVisible(true);
                    setLoading(false);
                }
            );
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const closePopup = () => {
        setPopupVisible(false);
    };


    return (
        <section className='ContactManinOuter'>
            <h1>Let's build a great project           </h1>
            <h3> DROP US A LINE</h3>
            <div className='ContactOuter'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className="ContactLeftPart col-lg-4 col-md-4 col-sm-4">

                            <div>
                                <h1 className='text-white text-center mb-3'>Contact Us</h1>
                                <ul>
                                    <li className='ContactleftPoint mb-3'>
                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                        <span>+91 9420206555</span>
                                    </li>
                                    <li className='ContactleftPoint mb-3'>
                                        <i class="fa fa-envelope" aria-hidden="true"></i>
                                        <span>admin@teople.co.in</span>
                                    </li>
                                    <li className='ContactleftPoint mb-3'>
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                        <span>Teople.co.in</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="ContactRightPart col-lg-7 col-md-7 col-sm-5 mx-auto">
                            <div className='ContactFormouter'>
                                <form ref={form} action="" onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-sm-12'>

                                            <div class="input-container">
                                                <i class="fa fa-user icon"></i>
                                                <input class="input-field" name='name' type="text" placeholder=" Name" id="name" />
                                                <span class="focus-border"></span>
                                                <label for="name">NAME*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-sm-12'>

                                            <div class="input-container">
                                                <i class="fa fa-envelope icon" aria-hidden="true"></i>
                                                <input class="input-field" type="text" name="email" placeholder="Email " id="email" />
                                                <span class="focus-border"></span>
                                                <label for="email">Email*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 '>

                                            <div class="input-container">
                                                <i class="fa fa-phone icon" aria-hidden="true"></i>
                                                <input class="input-field" name="mob" type="text" placeholder=" Mobile Number" id="mob" />
                                                <span class="focus-border"></span>
                                                <label for="mob">MOBILE NUMBER*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 '>

                                            <div class="input-container">
                                                <i class="fa fa-snowflake-o icon" aria-hidden="true"></i>
                                                {/* <input class="input-field" type="text" placeholder=" " id="name" /> */}
                                                <select class="input-field" id="dropdown" name='interested' value={selectedOption} onChange={handleChange}>
                                                    <option value="">INTERESTED IN*</option>
                                                    <option value="Web Developement">Web Developement</option>
                                                    <option value="Mobile Development">Mobile Development</option>
                                                    <option value="Cloud service">Cloud service</option>
                                                    <option value="Product Developement ">Product Developement </option>
                                                    <option value="Hardware-Software Integration ">Hardware-Software Integration </option>

                                                    <option value="Staffing Solutions ">Staffing Solutions </option>
                                                    <option value="UI/UX Design ">UI/UX Design </option>
                                                    <option value="IoT Developement ">IoT Developement </option>
                                                </select>
                                                <span class="focus-border"></span>
                                                {/* <label for="name">INTERESTED IN*</label> */}
                                            </div>
                                        </div>

                                        <div>
                                            <label for="message" class="message-label">
                                                <span class="icon" id='Icon'>&#x1F4AC;</span> MESSAGE*
                                            </label>
                                            <textarea id="message" name="message" rows="4" required></textarea>
                                        </div>

                                        <div className='belowFormPart'>

                                            <button type='submit' className='enqBtn' >
                                                SEND YOUR ENQUIRY
                                            </button>

                                        </div>


                                    </div>
                                </form>

                            </div>

                        </div>


                    </div>

                </div>
            </div>

            {/* Popup Message */}
            {popupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="popup-close" onClick={closePopup}>&times;</span>
                        {loading ? (
                            <div className="loader"></div>
                        ) : (
                            <>
                                <div className="popup-icon">
                                    <FaCheckCircle size={40} color="#28a745" />
                                </div>
                                <h3>Thank You</h3>
                                <p>{popupMessage}</p>
                            </>
                        )}
                    </div>
                </div>
            )}

        </section>
    )
}

export default ContactM