
//working

// import React, { useState, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
// // import emailjs from 'emailjs-com';
// import emailjs from '@emailjs/browser';

// function CareerInnerForm() {
//     const [selectedOption, setSelectedOption] = useState('');
//     const location = useLocation();
//     const profileInfo = location.state.profile;
//     console.log("crs", profileInfo);

//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         mob: "",
//         position: "",
//         linkedin: "",
//         portf: "",
//         avail: "",
//         exp: "",
//         msg: "",
//         resume: null,
//         submitted: false

//     });

//     const handleFileInputChange = async (e) => {
//         const file = e.target.files[0];
//         const base64 = await convertFileToBase64(file);

//         setFormData({
//             ...formData,
//             resume: base64
//         });
//         console.log("form data", formData);
//     };

//     const convertFileToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = error => reject(error);
//         });
//     };


//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const templateParams = {
//                 name: formData.name,
//                 email: formData.email,
//                 phone: formData.mob,
//                 position: formData.position,
//                 linkedin: formData.linkedin,
//                 portf: formData.portf,
//                 avail: formData.avail,
//                 exp: formData.exp,
//                 msg: formData.msg,
//                 // resume: formData.resume ? 'Attached' : 'Not Attached' // Just an example for the resume field
//                 resume: formData.resume // Send the base64-encoded file

//             };

//             await emailjs.send('service_aflps1h', 'template_qk4zzub', templateParams, 'IIFFeZhMLyRez8teD');

//             setFormData({
//                 ...formData,
//                 submitted: true
//             });

//             alert('Your application has been successfully submitted!');
//             console.log("data of user", formData)
//             console.log("resume of user", formData.resume)


//         } catch (error) {
//             console.error('Failed to submit form:', error);
//             alert('Failed to submit form. Please try again later.');
//         }
//     }

//     // const handleFileInputChange = (e) => {
//     //     setFormData({
//     //         ...formData,
//     //         resume: e.target.files[0]
//     //     });
//     //     console.log("form data", formData);
//     // };

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const fileInputRef = useRef(null);

//     const handleButtonClick = () => {
//         fileInputRef.current.click();
//     };

//     return (
//         <div>
//             <div className='careerOuter text-center'>
//                 <div className='container'>
//                     <div className="section-title">
//                         <h2>{profileInfo.courseName} VACANCY</h2>
//                     </div>

//                     <div className='row'>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                             <div className='CILeft'>
//                                 <h3> Job Profile : {profileInfo.courseName}</h3>
//                                 <div>
//                                     <h4>Job Requirement</h4>
//                                     <ul>
//                                         <li>Qualification : {profileInfo.details[0].qualification}</li>
//                                         <li>Experience : {profileInfo.details[0].exp}</li>
//                                     </ul>
//                                 </div>
//                                 <div>
//                                     <h4>Job Description</h4>
//                                     <ul>
//                                         {
//                                             profileInfo?.details[0]?.description.map((item) => (
//                                                 <li>{item}</li>
//                                             ))
//                                         }

//                                     </ul>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                            <div className='CIRIght'>
//                            <form onSubmit={handleSubmit}>
//                                 <div className='row'>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-user icon"></i>
//                                             <input className="input-field" type="text" name='name'
//                                                 onChange={handleChange}
//                                                 value={formData.name} placeholder=" " id="name" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="name">NAME*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-envelope icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='email'
//                                                 onChange={handleChange}
//                                                 value={formData.email} placeholder=" " id="email" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="email">Email*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='mob'
//                                                 onChange={handleChange}
//                                                 value={formData.mob} placeholder=" " id="mob" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="mob">MOBILE NUMBER*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='position'
//                                                 onChange={handleChange}
//                                                 value={formData.position} placeholder="position" id="position" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="position">POSITION APPLIED FOR*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='linkedin'
//                                                 onChange={handleChange}
//                                                 value={formData.linkedin} placeholder="linkedin" id="linkedin" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="linkedin">LINKEDIN PROFILE*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='portf'
//                                                 onChange={handleChange}
//                                                 value={formData.portf} placeholder=" " id="portf" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="portf">PORTFOLIO URL*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='avail'
//                                                 onChange={handleChange}
//                                                 value={formData.avail} placeholder=" " id="avail" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="avail">AVAILABILITY TO START*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className="input-container">
//                                             <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                             <input className="input-field" type="text" name='exp'
//                                                 onChange={handleChange}
//                                                 value={formData.exp} placeholder=" " id="exp" />
//                                             <span className="focus-border"></span>
//                                             <label htmlFor="exp">YEARS OF EXPERIENCE*</label>
//                                         </div>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <button type="button" className="add-file-btn" onClick={handleButtonClick}>
//                                             <i className="fa fa-plus-circle mx-3" aria-hidden="true"></i>
//                                             ADD FILE
//                                         </button>
//                                         <input
//                                             type="file"
//                                             ref={fileInputRef}
//                                             onChange={handleFileInputChange}
//                                             style={{ display: 'none' }}
//                                         />
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <label htmlFor="msg" className="message-label">
//                                             <span className="icon" id='innerIcon'>&#x1F4AC;</span> <span >COVER LETTER*</span>
//                                         </label>
//                                         <textarea id="msg" name="msg" onChange={handleChange} value={formData.msg} rows="4" required></textarea>
//                                     </div>
//                                     <div className='col-lg-12'>
//                                         <div className='belowFormPart'>
//                                             <div className='enqBtn' onClick={handleSubmit}>
//                                                 Apply
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </form>
//                            </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default CareerInnerForm;

//working code 

// import React, { useState, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
// import emailjs from '@emailjs/browser';

// function CareerInnerForm() {
//     const [selectedOption, setSelectedOption] = useState('');
//     const location = useLocation();
//     const profileInfo = location.state.profile;
//     console.log("crs", profileInfo);

//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         mob: "",
//         position: "",
//         linkedin: "",
//         portf: "",
//         avail: "",
//         exp: "",
//         msg: "",
//         resume: null,
//         submitted: false
//     });

//     const convertFileToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result.split(',')[1]); // Only base64 part
//             reader.onerror = error => reject(error);
//         });
//     };

//     const handleFileInputChange = async (e) => {
//         const file = e.target.files[0];
//         const base64 = await convertFileToBase64(file);

//         setFormData({
//             ...formData,
//             resume: {
//                 content: base64,
//                 filename: file.name,
//                 type: file.type
//             }
//         });
//         console.log("form data", formData);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const templateParams = {
//                 name: formData.name,
//                 email: formData.email,
//                 phone: formData.mob,
//                 position: formData.position,
//                 linkedin: formData.linkedin,
//                 portf: formData.portf,
//                 avail: formData.avail,
//                 exp: formData.exp,
//                 msg: formData.msg,
//                 attachments: [
//                     {
//                         content: formData.resume.content,
//                         filename: formData.resume.filename,
//                         type: formData.resume.type,
//                         disposition: 'attachment'
//                     }
//                 ]
//                 // resume: formData.resume // Send the base64-encoded file
//             };

//             await emailjs.send('service_aflps1h', 'template_qk4zzub', templateParams, 'IIFFeZhMLyRez8teD');

//             setFormData({
//                 ...formData,
//                 submitted: true
//             });

//             alert('Your application has been successfully submitted!');
//             console.log("data of user", formData);
//             console.log("resume of user", formData.resume);

//         } catch (error) {
//             console.error('Failed to submit form:', error);
//             alert('Failed to submit form. Please try again later.');
//         }
//     };

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const fileInputRef = useRef(null);

//     const handleButtonClick = () => {
//         fileInputRef.current.click();
//     };

//     return (
//         <div>
//             <div className='careerOuter text-center'>
//                 <div className='container'>
//                     <div className="section-title">
//                         <h2>{profileInfo.courseName} VACANCY</h2>
//                     </div>

//                     <div className='row'>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                             <div className='CILeft'>
//                                 <h3> Job Profile : {profileInfo.courseName}</h3>
//                                 <div>
//                                     <h4>Job Requirement</h4>
//                                     <ul>
//                                         <li>Qualification : {profileInfo.details[0].qualification}</li>
//                                         <li>Experience : {profileInfo.details[0].exp}</li>
//                                     </ul>
//                                 </div>
//                                 <div>
//                                     <h4>Job Description</h4>
//                                     <ul>
//                                         {
//                                             profileInfo?.details[0]?.description.map((item) => (
//                                                 <li>{item}</li>
//                                             ))
//                                         }

//                                     </ul>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                             <div className='CIRIght'>
//                                 <form onSubmit={handleSubmit}>
//                                     <div className='row'>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-user icon"></i>
//                                                 <input className="input-field" type="text" name='name'
//                                                     onChange={handleChange}
//                                                     value={formData.name} placeholder=" " id="name" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="name">NAME*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-envelope icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='email'
//                                                     onChange={handleChange}
//                                                     value={formData.email} placeholder=" " id="email" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="email">Email*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='mob'
//                                                     onChange={handleChange}
//                                                     value={formData.mob} placeholder=" " id="mob" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="mob">MOBILE NUMBER*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='position'
//                                                     onChange={handleChange}
//                                                     value={formData.position} placeholder="position" id="position" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="position">POSITION APPLIED FOR*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='linkedin'
//                                                     onChange={handleChange}
//                                                     value={formData.linkedin} placeholder="linkedin" id="linkedin" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="linkedin">LINKEDIN PROFILE*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='portf'
//                                                     onChange={handleChange}
//                                                     value={formData.portf} placeholder=" " id="portf" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="portf">PORTFOLIO URL*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='avail'
//                                                     onChange={handleChange}
//                                                     value={formData.avail} placeholder=" " id="avail" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="avail">AVAILABILITY TO START*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='exp'
//                                                     onChange={handleChange}
//                                                     value={formData.exp} placeholder=" " id="exp" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="exp">YEARS OF EXPERIENCE*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <button type="button" className="add-file-btn" onClick={handleButtonClick}>
//                                                 <i className="fa fa-plus-circle mx-3" aria-hidden="true"></i>
//                                                 ADD FILE
//                                             </button>
//                                             <input
//                                                 type="file"
//                                                 ref={fileInputRef}
//                                                 onChange={handleFileInputChange}
//                                                 style={{ display: 'none' }}
//                                             />
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <label htmlFor="msg" className="message-label">
//                                                 <span className="icon" id='innerIcon'>&#x1F4AC;</span> <span>COVER LETTER*</span>
//                                             </label>
//                                             <textarea id="msg" name="msg" onChange={handleChange} value={formData.msg} rows="4" required></textarea>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className='belowFormPart'>
//                                                 <div className='enqBtn' onClick={handleSubmit}>
//                                                     Apply
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default CareerInnerForm;





// import React, { useState, useRef } from 'react';
// import { useLocation } from 'react-router-dom';

// function CareerInnerForm() {
//     const [selectedOption, setSelectedOption] = useState('');
//     const location = useLocation();
//     const profileInfo = location.state.profile;
//     console.log("crs", profileInfo);

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         mob: '',
//         position: "",
//         linkedin: "",
//         portf: "",
//         avail: "",
//         exp: "",
//         msg: "",
//         resume: null,
//     });

//     const handleChange = (e) => {
//         const { name, value, files } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: files ? files[0] : value,
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const formDataToSend = new FormData();
//         formDataToSend.append('name', formData.name);
//         formDataToSend.append('email', formData.email);
//         formDataToSend.append('mob', formData.mob);
//         formDataToSend.append('position', formData.position);
//         formDataToSend.append('linkedin', formData.linkedin);
//         formDataToSend.append('portf', formData.portf);
//         formDataToSend.append('avail', formData.avail);
//         formDataToSend.append('exp', formData.exp);
//         formDataToSend.append('msg', formData.msg);
//         formDataToSend.append('resume', formData.resume);

//         await fetch('http://localhost:5000/send-email', {
//             method: 'POST',
//             body: formDataToSend,
//         });

//         // Handle response (success or error)
//     };



//     const fileInputRef = useRef(null);

//     const handleButtonClick = () => {
//         fileInputRef.current.click();
//     };

//     return (
//         <div>
//             <div className='careerOuter text-center'>
//                 <div className='container'>
//                     <div className="section-title">
//                         <h2>{profileInfo.courseName} VACANCY</h2>
//                     </div>

//                     <div className='row'>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                             <div className='CILeft'>
//                                 <h3> Job Profile : {profileInfo.courseName}</h3>
//                                 <div>
//                                     <h4>Job Requirement</h4>
//                                     <ul>
//                                         <li>Qualification : {profileInfo.details[0].qualification}</li>
//                                         <li>Experience : {profileInfo.details[0].exp}</li>
//                                     </ul>
//                                 </div>
//                                 <div>
//                                     <h4>Job Description</h4>
//                                     <ul>
//                                         {
//                                             profileInfo?.details[0]?.description.map((item) => (
//                                                 <li>{item}</li>
//                                             ))
//                                         }

//                                     </ul>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className='col-lg-6 col-md-6 col-sm-12'>
//                             <div className='CIRIght'>
//                                 <form onSubmit={handleSubmit}>
//                                     <div className='row'>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-user icon"></i>
//                                                 <input className="input-field" type="text" name='name'
//                                                     onChange={handleChange}
//                                                     required value={formData.name} placeholder=" " id="name" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="name">NAME*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-envelope icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='email'
//                                                     onChange={handleChange}
//                                                     required value={formData.email} placeholder=" " id="email" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="email">Email*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='mob'
//                                                     onChange={handleChange}
//                                                     required value={formData.mob} placeholder=" " id="mob" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="mob">MOBILE NUMBER*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='position'
//                                                     onChange={handleChange}
//                                                     required value={formData.position} placeholder="position" id="position" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="position">POSITION APPLIED FOR*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='linkedin'
//                                                     onChange={handleChange}
//                                                     required value={formData.linkedin} placeholder="linkedin" id="linkedin" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="linkedin">LINKEDIN PROFILE*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='portf'
//                                                     onChange={handleChange}
//                                                     required value={formData.portf} placeholder=" " id="portf" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="portf">PORTFOLIO URL*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='avail'
//                                                     onChange={handleChange}
//                                                     required value={formData.avail} placeholder=" " id="avail" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="avail">AVAILABILITY TO START*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className="input-container">
//                                                 <i className="fa fa-phone icon" aria-hidden="true"></i>
//                                                 <input className="input-field" type="text" name='exp'
//                                                     onChange={handleChange}
//                                                     required value={formData.exp} placeholder=" " id="exp" />
//                                                 <span className="focus-border"></span>
//                                                 <label htmlFor="exp">YEARS OF EXPERIENCE*</label>
//                                             </div>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <button type="button" className="add-file-btn" onClick={handleButtonClick}>
//                                                 <i className="fa fa-plus-circle mx-3" aria-hidden="true"></i>
//                                                 ADD FILE
//                                             </button>
//                                             <input
//                                               type="file" name="pdf" accept="application/pdf" onChange={handleChange} required
//                                                 ref={fileInputRef}
//                                                 style={{ display: 'none' }}
//                                             />
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <label htmlFor="msg" className="message-label">
//                                                 <span className="icon" id='innerIcon'>&#x1F4AC;</span> <span>COVER LETTER*</span>
//                                             </label>
//                                             <textarea id="msg" name="msg" onChange={handleChange}  value={formData.msg} rows="4" required></textarea>
//                                         </div>
//                                         <div className='col-lg-12'>
//                                             <div className='belowFormPart'>
//                                                 <div className='enqBtn' onClick={handleSubmit}>
//                                                     Apply
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default CareerInnerForm;


import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {  FaCheckCircle } from 'react-icons/fa'; // Import icons


function CareerInnerForm() {
    const [selectedOption, setSelectedOption] = useState('');
    const location = useLocation();
    const profileInfo = location.state.profile;
    console.log("crs", profileInfo);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mob: '',
        position: "",
        linkedin: "",
        portf: "",
        avail: "",
        exp: "",
        msg: "",
        resume: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('mob', formData.mob);
        formDataToSend.append('position', formData.position);
        formDataToSend.append('linkedin', formData.linkedin);
        formDataToSend.append('portf', formData.portf);
        formDataToSend.append('avail', formData.avail);
        formDataToSend.append('exp', formData.exp);
        formDataToSend.append('msg', formData.msg);
        formDataToSend.append('resume', formData.resume); // Ensure the field name matches

        const response = await fetch('http://localhost:5000/send-email', {
            method: 'POST',
            body: formDataToSend,
        });

        if (response.ok) {
            // Handle success
            console.log('Form submitted successfully');
            setPopupMessage('Your message has been sent successfully!');
            setPopupVisible(true);
            setLoading(false);
        } else {
            // Handle error
            console.error('Form submission error');
            setPopupMessage('Failed to send email. Please try again later.');
            setPopupVisible(true);
            setLoading(false);
        }
    };

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const closePopup = () => {
        setPopupVisible(false);
    };


    return (
        <div>
            <div className='careerOuter text-center'>
                <div className='container'>
                    <div className='carrerBg'>
                    <div className="section-title">
                        <h2>{profileInfo.courseName} VACANCY</h2>
                    </div>
                    </div>
                   

                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='CILeft'>
                                <h3> Job Profile : {profileInfo.courseName}</h3>
                                <div>
                                    <h4>Job Requirement</h4>
                                    <ul>
                                        <li>Qualification : {profileInfo.details[0].qualification}</li>
                                        <li>Experience : {profileInfo.details[0].exp}</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>Job Description</h4>
                                    <ul>
                                        {
                                            profileInfo?.details[0]?.description.map((item) => (
                                                <li key={item}>{item}</li>
                                            ))
                                        }

                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='CIRIght'>
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i className="fa fa-user icon"></i>
                                                <input className="input-field" type="text" name='name'
                                                    onChange={handleChange}
                                                    required value={formData.name} placeholder=" " id="name" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="name">NAME*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i className="fa fa-envelope icon" aria-hidden="true"></i>
                                                <input className="input-field" type="text" name='email'
                                                    onChange={handleChange}
                                                    required value={formData.email} placeholder=" " id="email" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="email">Email*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i className="fa fa-phone icon" aria-hidden="true"></i>
                                                <input className="input-field" type="text" name='mob'
                                                    onChange={handleChange}
                                                    required value={formData.mob} placeholder=" " id="mob" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="mob">MOBILE NUMBER*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i class="fa fa-briefcase icon"></i>
                                                <input className="input-field" type="text" name='position'
                                                    onChange={handleChange}
                                                    required value={formData.position} placeholder="position" id="position" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="position">POSITION APPLIED FOR*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i class="fa fa-linkedin-square icon" aria-hidden="true"></i>
                                                <input className="input-field" type="text" name='linkedin'
                                                    onChange={handleChange}
                                                    required value={formData.linkedin} placeholder="linkedin" id="linkedin" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="linkedin">LINKEDIN PROFILE*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i class="fa fa-link icon"></i>
                                                <input className="input-field" type="text" name='portf'
                                                    onChange={handleChange}
                                                    required value={formData.portf} placeholder=" " id="portf" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="portf">PORTFOLIO URL*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i class="fa fa-check icon"></i>
                                                <input className="input-field" type="text" name='avail'
                                                    onChange={handleChange}
                                                    required value={formData.avail} placeholder=" " id="avail" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="avail">AVAILABILITY TO START*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="input-container">
                                                <i class="fa fa-graduation-cap icon"></i>
                                                <input className="input-field" type="text" name='exp'
                                                    onChange={handleChange}
                                                    required value={formData.exp} placeholder=" " id="exp" />
                                                <span className="focus-border"></span>
                                                <label htmlFor="exp">YEARS OF EXPERIENCE*</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <button type="button" className="add-file-btn" onClick={handleButtonClick}>
                                                <i className="fa fa-plus-circle mx-3" aria-hidden="true"></i>
                                                ADD FILE
                                            </button>
                                            <input
                                                type="file" name="resume" accept="application/pdf" onChange={handleChange} required
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        <div className='col-lg-12'>
                                            <label htmlFor="msg" className="message-label">
                                                <span className="icon" id='innerIcon'>&#x1F4AC;</span> <span>COVER LETTER*</span>
                                            </label>
                                            <textarea id="msg" name="msg" onChange={handleChange} value={formData.msg} rows="4" required></textarea>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='belowFormPart'>
                                                <button type="submit" className='enqBtn'>
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup Message */}
            {popupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="popup-close" onClick={closePopup}>&times;</span>
                        {loading ? (
                            <div className="loader"></div>
                        ) : (
                            <>
                                <div className="popup-icon">
                                    <FaCheckCircle size={40} color="#28a745" />
                                </div>
                                <h3>Thank You</h3>
                                <p>{popupMessage}</p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CareerInnerForm;
