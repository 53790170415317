import React, { useState, useRef, useEffect } from 'react';

function VideoShow() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  const videos = [
    require('../../Video/v1.mp4'),
    require('../../Video/v2.mp4'),
    require('../../Video/v3.mp4'),
    require('../../Video/v4.mp4'),
    require('../../Video/v5.mp4'),
    require('../../Video/v6.mp4')
  ];

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex < videos.length - 1 ? prevIndex + 1 : 0
    );
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch(error => {
        console.error('Video play error:', error);
      });
    }
  }, [currentVideoIndex]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        src={videos[currentVideoIndex]}
        controls
        autoPlay
        muted
        onEnded={handleVideoEnd}
        style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
      />
    </div>
  );
}

export default VideoShow;

// import React, { useState, useRef, useEffect } from 'react';

// function VideoShow() {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
//   const videoRef = useRef(null);

//   const videos = [
//     require('../../Video/v1.mp4'),
//     require('../../Video/v2.mp4'),
//     require('../../Video/v3.mp4'),
//     require('../../Video/v4.mp4'),
//     require('../../Video/v5.mp4'),
//     require('../../Video/v6.mp4')
//   ];

//   const handleVideoEnd = () => {
//     setCurrentVideoIndex((prevIndex) =>
//       prevIndex < videos.length - 1 ? prevIndex + 1 : 0
//     );
//   };

//   useEffect(() => {
//     if (videoRef.current) {
//       videoRef.current.play();
//     }
//   }, [currentVideoIndex]);

//   return (
//     <div className="video-container">
//       <video
//         ref={videoRef}
//         src={videos[currentVideoIndex]}
//         controls
//         autoPlay
//         onEnded={handleVideoEnd}
//         style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
//       />
//     </div>
//   );
// }

// export default VideoShow;

// import React, { useState, useRef } from 'react';

// function VedioShow() {
//     const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
//   const videoRef = useRef(null);

//   const videos = [
//     require('../../Video/v1.mp4'),
//     require('../../Video/v2.mp4'),
//     require('../../Video/v3.mp4'),
//     require('../../Video/v4.mp4'),
//     require('../../Video/v5.mp4'),
//     require('../../Video/v6.mp4')
//   ];

//   const handleVideoEnd = () => {
//     if (currentVideoIndex < videos.length - 1) {
//       setCurrentVideoIndex(currentVideoIndex + 1);
//     } else {
//       setCurrentVideoIndex(0); // or you can stop the playback
//     }
//   };

//   return (
//     <div className="video-container">
//          <video
//         ref={videoRef}
//         src={videos[currentVideoIndex]}
//         controls
//         autoPlay
//         onEnded={handleVideoEnd}
//         style={{ width: '100%', height:"100vh", objectFit:"cover"}}
//       />
//     </div>
//   )
// }

// export default VedioShow
