import React, { useState } from "react";
import logo from '../Images/Logo.png';
import './CSS/NavbarHeader.css'
import lets from '../Images/Letstalk.png'
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  return (
    <nav id="menu" className={`navbar navbar-default navbar-fixed-top`}>
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"

          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
          <Link to="/">  <img src={logo} alt={logo} /></Link>
          </a>{" "}
        </div>


        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
                  <a href="#features" className="page-scroll">
                    Home
                  </a>
                </li> */}
            {/* <li>
                  <a href="#about" className="page-scroll">
                    About
                  </a>
                </li> */}
            <li>
              <a href="#" className="page-scroll navWord">
                <Link to="/serpg">Service</Link>

              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Contact
              </a>
            </li> */}
            <li>
              <a href="#career" className="page-scroll navWord" >
                <Link to="/career">Career</Link>
              </a>
            </li>
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">                  <Link to="/letstalk"><img className="letsImg" src={lets} alt={lets} /></Link>


              </a>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  );
};
