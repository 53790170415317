import React from 'react';
import './CSS/footer.css'

function Footer() {
    const bubbles = Array.from({ length: 20 });

    return (
        <div className='footerOuter'>
            <footer class="footer">
                <div class="footer-content">
                    <div class="column">
                        <div className='section-title'>
                            <h2>Teople Technolgies</h2>
                        </div>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About</a></li>
                            <li><a href="#">Links</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a href="#">Career</a></li>

                        </ul>
                    </div>
                    <div class="column">
                        <div className='section-title'>
                            <h2>Contact</h2>
                        </div>
                        <p><span role="img" aria-label="location">📍</span> Raj residency, Pune</p>
                        <p><span role="img" aria-label="phone">📞</span> +91 9420206555</p>
                        <p><span role="img" aria-label="email">✉️</span> admin@teople.co.in</p>
                    </div>
                    <div class="column">
                        <div className='section-title'>
                            <h2>Map</h2>
                        </div>
                        <div className='mapBox'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.130526069341!2d73.74047307424087!3d18.65813786495948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb540191470f%3A0x4949c74510764f43!2sRaj%20Residency!5e0!3m2!1sen!2sin!4v1712730646790!5m2!1sen!2sin"
                                width="100%"
                                height="150"
                                className='ifr'
                                style={{ border: 0 }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Maps Location"
                            ></iframe>
                        </div>
                        {/* <p>Latest Shape news, articles, and resources sent straight to your inbox every month.</p>
                        <form>
                            <input type="email" placeholder="Your Email" />
                            <button type="submit">Subscribe</button>
                        </form> */}
                    </div>
                </div>
            </footer>

            {/* <div className="container">
                {bubbles.map((_, index) => (
                    <div key={index} className="bubble"></div>
                ))}
            </div> */}

        </div>
    )
}

export default Footer