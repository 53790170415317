
import React, { useState, useEffect } from "react";
import { Header } from "./header";
import { About } from "./about";
import { Services } from "./services";
import { Testimonials } from "./testimonials";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import ContactM from "./ContactM";
import Technology from "./Technology";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

function Home() {

    const [landingPageData, setLandingPageData] = useState({});

    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Header data={landingPageData.Header} />

            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Testimonials data={landingPageData.Testimonials} />
            <Technology data={landingPageData.technology}/>
            <ContactM />


            {/* <Features data={landingPageData.Features} /> */}
            {/* <Gallery data={landingPageData.Gallery} /> */}
            {/* <Team data={landingPageData.Team} /> */}
            {/* <Contact data={landingPageData.Contact} /> */}
        </div>
    )
}

export default Home