import React from "react";
import vid1 from '../Video/vid11.mp4'
import VedioShow from "./innerComponent/VedioShow";

export const Header = (props) => {
  console.log("Heading props", props)
  return (
    // <header id="header">
    //   <div className="intro">
    //     <div className="overlay">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md-8 col-md-offset-2 intro-text">
    //             <h1>
    //               {props.data ? props.data.title : "Loading"}
    //               <span></span>
    //             </h1>
    //             <p>{props.data ? props.data.paragraph : "Loading"}</p>
    //             <a
    //               href="#features"
    //               className="btn btn-custom btn-lg page-scroll"
    //             >
    //               Learn More
    //             </a>{" "}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
    <header id="header">
    <div className="intro">
      {/* <video autoPlay loop muted className="background-video">
        <source src={vid1} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
          <VedioShow/>

    
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
              {/* <a
                href="#features"
                className="btn btn-custom btn-lg page-scroll"
              >
                Learn More
              </a>{" "} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  
  );
};
