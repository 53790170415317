import React from 'react';
import './CSS/techCss.css';
import {
    FaJava, FaReact, FaNodeJs, FaPython, FaHtml5, FaCss3Alt, FaBootstrap,
    FaAngular, FaPhp, FaJs, FaAws
} from "react-icons/fa";

import { SiSpringboot, SiMysql, SiMicrosoftazure, SiGooglecloud, SiMariadb, SiMongodb, SiAndroid } from "react-icons/si";

function Technology(props) {
    return (
        <div>
            <div id="features" className="text-center">
                <div className="container">
                    <div className="section-title">
                        <h2>Technology</h2>
                    </div>
                    <div className="grid-container">
                        <div>
                            <div className="TechIcon">
                                <FaJava size={64} />
                            </div>
                            <h3>JAVA</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiSpringboot size={64} />
                            </div>
                            <h3>SPRING BOOT</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaNodeJs size={64} />
                            </div>
                            <h3>NODE JS</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaPython size={64} />
                            </div>
                            <h3>PYTHON</h3>
                        </div>

                        <div>
                            <div className="TechIcon">
                                <FaHtml5 size={64} />
                            </div>
                            <h3>HTML</h3>
                        </div>
                                        <div>
                            <div className="TechIcon">
                                <FaCss3Alt size={64} />
                            </div>
                            <h3>CSS</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaJs size={64} />
                            </div>
                            <h3>FaJs</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaReact size={64} />
                            </div>
                            <h3>REACT</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaAngular size={64} />
                            </div>
                            <h3>ANGULAR</h3>
                        </div>
                        
                        <div>
                            <div className="TechIcon">
                                <SiMysql size={64} />
                            </div>
                            <h3>MySQL</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiMariadb size={64} />
                            </div>
                            <h3>Maria DB</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiMongodb size={64} />
                            </div>
                            <h3>Mongo DB</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <FaAws size={64} />
                            </div>
                            <h3>AWS</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiMicrosoftazure size={64} />
                            </div>
                            <h3>Azure</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiGooglecloud size={64} />
                            </div>
                            <h3>Google Cloud</h3>
                        </div>
                        <div>
                            <div className="TechIcon">
                                <SiAndroid size={64} />
                            </div>
                            <h3>Android</h3>
                        </div>
                    </div>

                    {/* <div className="row">

                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaJava size={64} />
                            </div>
                            <h3>JAVA</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaHtml5 size={64} />
                            </div>
                            <h3>SPRING BOOT</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiSpringboot size={64} />
                            </div>
                            <h3>PHP</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaPython size={64} />
                            </div>
                            <h3>PYTHON</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaNodeJs size={64} />
                            </div>
                            <h3>NODE JS</h3>
                        </div>


                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaHtml5 size={64} />
                            </div>
                            <h3>HTML</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaCss3Alt size={64} />
                            </div>
                            <h3>CSS</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaJs size={64} />
                            </div>
                            <h3>FaJs</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaReact size={64} />
                            </div>
                            <h3>REACT</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaAngular size={64} />
                            </div>
                            <h3>ANGULAR</h3>
                        </div>

                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiMysql size={64} />
                            </div>
                            <h3>MySQL</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiMariadb size={64} />
                            </div>
                            <h3>Maria DB</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiMongodb size={64} />
                            </div>
                            <h3>Mongo DB</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <FaAws size={64} />
                            </div>
                            <h3>AWS</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiMicrosoftazure size={64} />
                            </div>
                            <h3>Azure</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiGooglecloud size={64} />
                            </div>
                            <h3>Google Cloud</h3>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <div className="TechIcon">
                                <SiAndroid size={64} />
                            </div>
                            <h3>Android</h3>
                        </div>


                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Technology// import React from 'react'

// function Technology(props) {
//   return (
//     <div>
//          <div id="features" className="text-center">
//       <div className="container">
//         <div className="col-md-10 col-md-offset-1 section-title">
//           <h2>Technology</h2>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
//                   {" "}
//                   <i className={d.icon}></i>
//                   <h3>{d.title}</h3>
//                   <p>{d.text}</p>
//                 </div>
//               ))
//             : "Loading..."}
//         </div>
//       </div>
//     </div>
//     </div>
//   )
// }

// export default Technology