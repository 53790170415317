import React, { useEffect, useState } from 'react';
import './CSS/career.css';
import { Link, useNavigate } from "react-router-dom";
import JsonData from "../data/data.json";


function Career() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(JsonData.career)
    console.log("data",data)
  }, [])

  const handleClickCard=(profile)=>{
    navigate('/careerinner', {state : {profile}})
  }
  return (
    <div className='careerOuter text-center'>
      <div className='container'>
        <div className=" section-title">
          <h2>Career</h2>
        </div>
        <div className="careerGrid">
          {
            data.map((item, index) => (
              <div onClick={()=>{handleClickCard(item)}}  key={index}>
                <div className='careerCard'>
                  <h3>{item.courseName}</h3>
                </div>
              </div>
            ))
          }
        


        </div>


      </div>
    </div>
  )
}

export default Career