// import React, { useState, useEffect } from "react";
// import {Navigation} from './components/navigation';
// import { Header } from "./components/header";
// import { Features } from "./components/features";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
// import { Contact } from "./components/contact";
// import JsonData from "./data/data.json";
// import SmoothScroll from "smooth-scroll";
// import "./App.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Career from "./components/Career";

// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

// const Home = ({ data }) => (
//   <>
//     <Header data={data.Header} />
//     <Features data={data.Features} />
//     <About data={data.About} />
//     <Services data={data.Services} />
//     <Testimonials data={data.Testimonials} />
//     <Contact data={data.Contact} />
//   </>
// );

// const App = () => {
//   const [landingPageData, setLandingPageData] = useState({});
//   useEffect(() => {
//     setLandingPageData(JsonData);
//   }, []);

//   return (
//     <div>

//       <Router>
//       <Navigation />
    

//         <Routes>
//           <Route path="/header" element={<Header  data={landingPageData.Header}/>}/>
//           {/* <Route path="/feature" element={<Features  data={landingPageData.Features}/>}/>
//           <Route path="/about" element={<About  data={landingPageData.About}/>}/>
//           <Route path="/services" element={<Services  data={landingPageData.Services}/>}/>
//           <Route path="/test" element={<Testimonials  data={landingPageData.Testimonials}/>}/> */}
//           {/* <Route path="/contact" element={<Contact  data={landingPageData.Contact}/>}/> */}
//           <Route path="/career" element={<Career  data={landingPageData.Services}/>}/>


//         </Routes>
//       </Router>
//       {/* <Header data={landingPageData.Header} /> */}
//       {/* <Features data={landingPageData.Features} /> */}
//       {/* <About data={landingPageData.About} /> */}
//       {/* <Services data={landingPageData.Services} /> */}
//       {/* <Gallery data={landingPageData.Gallery} /> */}
//       {/* <Testimonials data={landingPageData.Testimonials} /> */}
//       {/* <Team data={landingPageData.Team} /> */}
//       {/* <Contact data={landingPageData.Contact} /> */}
//     </div>
//   );
// };

// export default App;


import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import ContactM from "./components/ContactM";
import Technology from "./components/Technology";
import Home from "./components/Home";
import Career from "./components/Career";
import LetsTalk from "./components/LetsTalk";
import ServicePag from "./components/ServicePag";
import Footer from "./components/Footer";
import CareerInnerForm from "./components/CareerInnerForm";
import './components/CSS/Responsive.css'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation />

        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About data={landingPageData.About} />} />
          <Route path="/services" element={<Services data={landingPageData.Services} />} />
          <Route path="/testimonials" element={<Testimonials data={landingPageData.Testimonials} />} />
          <Route path="/contact" element={<ContactM />} />
          <Route path="/career" element={<Career/>} data={landingPageData.career}  />
          <Route path="/letstalk" element={<LetsTalk/>} />
          <Route path="/serpg" element={<ServicePag/>} />
          <Route path="/careerinner" element={<CareerInnerForm/>} />

          
          

          {/* Uncomment and add paths for other components as needed */}
          {/* <Route path="/features" element={<Features data={landingPageData.Features} />} /> */}
          {/* <Route path="/gallery" element={<Gallery data={landingPageData.Gallery} />} /> */}
          {/* <Route path="/team" element={<Team data={landingPageData.Team} />} /> */}
          {/* <Route path="/technology" element={<Technology data={landingPageData.technology} />} /> */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;

// import React, { useState, useEffect } from "react";
// import { Navigation } from "./components/navigation";
// import { Header } from "./components/header";
// import { Features } from "./components/features";
// import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
// import { Contact } from "./components/contact";
// import JsonData from "./data/data.json";
// import SmoothScroll from "smooth-scroll";
// import "./App.css";
// import ContactM from "./components/ContactM";
// import Technology from "./components/Technology";

// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

// const App = () => {
//   const [landingPageData, setLandingPageData] = useState({});
//   useEffect(() => {
//     setLandingPageData(JsonData);
//   }, []);

//   return (
//     <div>
//       <Navigation />
//       <Header data={landingPageData.Header} />

//       <About data={landingPageData.About} />
//       <Services data={landingPageData.Services} />
//       <Testimonials data={landingPageData.Testimonials} />
//       {/* <Technology data={landingPageData.technology}/> */}
//       <ContactM/>


//       {/* <Features data={landingPageData.Features} /> */}
//       {/* <Gallery data={landingPageData.Gallery} /> */}
//       {/* <Team data={landingPageData.Team} /> */}
//       {/* <Contact data={landingPageData.Contact} /> */}

//     </div>
//   );
// };

// export default App;
