import React from "react";
import { FaMicrochip } from "react-icons/fa6";


export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">

          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-globe" ></i>
            <div className="service-desc">
              <h3>Web Developement</h3>
              {/* <p>Information</p> */}
              {/* <p>Our expert web development services create engaging, user-friendly, and high-performing websites tailored to your business needs. We use the latest technologies and best practices, including responsive design, scalable architecture, and secure web applications, to build outstanding websites.</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-android" ></i>
            <div className="service-desc">
              <h3>Mobile Development</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-cloud-download" ></i>
            <div className="service-desc">
              <h3>Cloud service</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-desktop" ></i>
            <div className="service-desc">
              <h3>Product Developement</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-briefcase" ></i>
            <div className="service-desc">
              <h3>Hardware-Software Integration</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-users" ></i>
            <div className="service-desc">
              <h3>Staffing Solutions</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <i className="fa fa-paint-brush" ></i>
            <div className="service-desc">
              <h3>UI/UX Design</h3>
              {/* <p>Information</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            {/* <i className="fa fa-microchip" ></i> */}
            <i class="fa fa-microchip" aria-hidden="true"> <FaMicrochip /></i>
            <div className="service-desc">
              <h3>IoT Developement</h3>
              {/* <p>Information</p> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

// import React from "react";

// export const Services = (props) => {
//   return (
//     <div id="services" className="text-center">
//       <div className="container">
//         <div className="section-title">
//           <h2>Our Services</h2>
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
//             dapibus leonec.
//           </p>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-lg-3 col-md-6 col-sm-12">
//                   {" "}
//                   <i className={d.icon}></i>
//                   <div className="service-desc">
//                     <h3>{d.name}</h3>
//                     <p>{d.text}</p>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };
